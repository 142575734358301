@import 'shared/styles/_variables.scss';

.search-result-row {
  @extend %p3-font-size;
  background-color: $white;
  cursor: pointer;
  padding: 10px;
  padding-left: 30px;
  z-index: 501;
  box-sizing: border-box;
  border: 1px solid $gray-400;
  border-top: 0;

  svg {
    width: 40px;
    height: 40px;
  }

  span {
    margin-right: 20px;
  }
}

.search-result-row:nth-child(odd) {
  // background-color: $gray-200;
}

.search-result-row:focus {
  background-color: $gray-500;
  color: white;
  outline: none;
}

@media (hover: hover) {
  .search-result-row:hover {
    background-color: $gray-300;
    color: $black;
  }
  .search-result-row.no-result {
    box-shadow: none;
  }
}
