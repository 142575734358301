@import 'shared/styles/_variables.scss';

.leaflet-marker-icon.leaflet-div-icon {
  background: transparent;
  border: none;

  path {
    fill: $secondary;
  }
}
