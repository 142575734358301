body {
  overflow-x: hidden;
}

/* width */
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0px 10px $gray-600;
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $dark;
  border-radius: 10px;
}

// Bootstrap

.no-gutter > [class*='col-'],
.no-gutter > [class*='col'] {
  padding-right: 0;
  padding-left: 0;
}

// Layout

%layout-width-wrapper {
  box-sizing: border-box;
  margin: 0 auto !important;
  padding: 0 1rem !important;
  width: 100% !important;

  @include media-breakpoint-up(xxl) {
    // padding: 0 !important;
    // max-width: 2200px !important;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .full-bleed--mobile {
    @include media-breakpoint-down(md) {
      margin-left: -1rem;
      margin-right: -1rem;
      flex: none;
      max-width: none;
      width: calc(100% + 2rem);
    }
  }
}

%layout-width-wrapper--extended {
  @include media-breakpoint-up(lg) {
    // margin-left: 20% !important;
    // margin-right: 20px !important;
    padding: 0 !important;
    width: 100% !important;
    // max-width: 1600px !important;
  }
}

.layout-width-wrapper {
  @extend %layout-width-wrapper;
}

.layout-width-wrapper--extended {
  @extend %layout-width-wrapper;
  // @extend %layout-width-wrapper--extended;
  padding: 0 !important;
}

.max-width-wrapper {
  @extend %layout-width-wrapper;
  max-width: 1044px !important;
}

.layout-width-wrapper--padded {
  @extend %layout-width-wrapper;
  padding: 0 1rem !important;
}

.touch-left {
  @include media-breakpoint-up(md) {
    margin-left: -15px;
  }
}

.touch-right {
  margin-right: -15px;
}

.padding-xs-0 {
  @include media-breakpoint-between(xs, xs) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.padding-sm-0 {
  @include media-breakpoint-between(sm, sm) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.padding-xs-sm-0 {
  @include media-breakpoint-between(xs, sm) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.pt-6 {
  padding-top: 4em !important;
}
.pb-6 {
  padding-bottom: 4em !important;
}

.Toastify__toast.Toastify__toast--success {
  background-color: $success;
}

.Toastify__toast.Toastify__toast--info {
  background-color: $info;
}

.Toastify__toast.Toastify__toast--danger {
  background-color: $danger;
}
