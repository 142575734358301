@import 'shared/styles/_variables.scss';

select.form-control {
  color: $gray-500;
}
select.form-control.valued {
  color: $black;
}

.input-group-text,
.form-control,
.input-group > .form-control {
  box-shadow: none;
  outline: none;
  // border: none;
  // border-radius: 0;
  // border-bottom: $input-border-mobile;

  // @include media-breakpoint-up(sm) {
  //   border-bottom: $input-border;
  // }
}
.form-control:focus,
.form-control:focus-within {
  outline: $secondary; // box-shadow: none;
  // border-bottom: $input-border-focus-mobile;
  @include media-breakpoint-up(sm) {
    // border-bottom: $input-border-focus;
  }
}
input.form-control:focus,
input.form-control {
  color: $black;
}
input.form-control::placeholder {
  color: $gray-500;
  font-style: italic;
}

.form-control-lg {
  @extend %p1-font-size;
}

.xl-form-control {
  border: 0;
  border-bottom: 4px dotted $gray-400;
  background-color: $white;
  height: calc(3.5rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
  outline: none;
  box-shadow: none;

  @include media-breakpoint-up(xl) {
    font-size: 2rem;
    line-height: 2.25;
  }
}
