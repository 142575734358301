@import 'shared/styles/_variables.scss';

button.button {
  padding: 0;
  border: 0;
  outline: none;
  box-shadow: none;
  background-color: transparent;
}

button.button:focus {
  outline: none;
}

button.btn-primary:disabled {
  background-color: lighten($info, 10%);
}

.btn-loader {
  position: relative;
}

.btn--small {
  @extend %small-font-size;
}

.button-loader__container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.btn-outline-primary,
.btn-outline-secondary,
.btn-outline-info,
.btn-outline-warning,
.btn-outline-danger {
  background-color: white;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active {
  color: white;
}

.btn-link {
  color: $text-link;
}
a.btn-link:hover {
  text-decoration: none;
}

.link-button {
  background-color: transparent;
  border: 0;
  box-shadow: none;
}

.link-button:disabled {
  color: $gray-300 !important;
  cursor: initial;
  pointer-events: none;
}

.tab--disabled {
  background-color: $tab-disabled;
  color: $black;
  outline: none;
}

.tab--dark-inverse {
  background-color: $white;
  color: $gray-900;
  outline: none;

  @media (hover: hover) {
    &:hover {
      background-color: $tab-dark-hover;
      color: $dark;
    }
  }

  &:focus {
    background-color: $tab-dark-hover;
    color: $dark;
    outline: none;
  }

  &.active {
    background-color: $dark;
    color: $white;
  }

  &:disabled {
    background-color: $tab-disabled;
    color: $black;
  }
}

.tab--primary {
  background-color: $tab-primary;
  color: $gray-900;
  outline: none;

  @media (hover: hover) {
    &:hover {
      background-color: $tab-primary-hover;
      color: $black;
    }
  }

  &:focus {
    background-color: $tab-primary-hover;
    color: $black;
    outline: none;
  }

  &.active {
    background-color: $tab-primary-active;
    color: $black;
  }

  &:disabled {
    background-color: $tab-disabled;
    color: $black;
  }
}

.tab--secondary {
  color: $gray-900;
  background-color: $tab-secondary;

  @media (hover: hover) {
    &:hover {
      background-color: $tab-secondary-hover;
      color: $black;
    }
  }

  &.active {
    background-color: $tab-secondary-active;
    color: $black;
  }

  &:disabled {
    background-color: $tab-disabled;
    color: $black;
  }
}

// Custom bootstrap radios

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: $secondary;
}

.custom-control-label::before,
.custom-control-label::after {
  top: 2px;
}

.ghost-button {
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  display: inline-block;
  padding: 0;
  margin: 0;

  &:focus {
    outline: none;
  }
}
