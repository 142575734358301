@import 'shared/styles/_variables.scss';

.dataset-info {
  @extend %p3-font-size;
  color: $dark;

  span {
    margin-right: 5px;
  }
}
