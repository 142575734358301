body.modal-open {
  .modal.show {
    padding-right: 0 !important;
    z-index: 10010;
  }
}

.modal-footer.row {
  & > :not(:last-child),
  & > :not(:first-child) {
    margin-left: initial;
    margin-right: initial;
  }
}
