@import 'shared/styles/bootstrap/_variables.scss';


.search-results {
  background-color: $white;
  position: absolute;
  width: 100%;
  z-index: 502;

  .search-results__wrapper {
    outline-color: $input-btn-focus-color;
    outline-width: $input-btn-focus-width;
    background-color: $white;
    position: absolute;
    margin: 0;
    width: 100%;
  }
}
