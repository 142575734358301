@import 'shared/styles/_variables.scss';

.footer {
  height: 24px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10000000;

  .footer-nav {
    background-color: #c4c4c4;
    padding: 0.25em 0;
  }

  .footer-text {
    @extend %p3-font-size;

    p {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
      padding: 0 2rem;
    }
  }
}
