@import 'shared/styles/_variables.scss';

.filter {
  width: 100%;

  .form-row,
  .filter-component__wrapper {
    width: 100%;
  }

  .paramset__label {
    margin-top: 10px;
    margin-left: 32px;
    font-weight: bold;
  }
  .paramset__label,
  .paramset--new-button {
    @extend %p3-font-size;
    margin-bottom: 0px;
  }

  .filter-control {
    margin: 0px 2px;
  }

  .filter-component__paramsets {
    padding: 0px 20px;
  }

  .filter-component__paramsets-wrapper {
    background-color: $white;
  }

  .modifying-paramset .fieldset {
    // margin-bottom: 10px;
  }
}

.filter-component {
}
