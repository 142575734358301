// Color system

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #f4f4f5 !default;
$gray-300: #e1e1e1 !default;
$gray-400: #b9b8b8 !default;
$gray-500: #818181 !default;
$gray-600: #6c757d !default;
$gray-700: #5b5858 !default;
$gray-800: #343a40 !default;
$gray-900: #424a54 !default;
$black: #0a2126 !default;

$rhino: #424a54 !default;
$rhino-200: #c0c7c4 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
  ),
  $grays
);

$blue: #007bff !default;
$paleCornflowerBlue: #b8d8ff !default;
$blizzard: #95bae8 !default;
$danube: #1465b7 !default;
$congress-blue: #014c8d !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #ffc4cd !default;
$red: #dc3545 !default;
$orange: #f5862c !default;
$texasRose: #f4ac58 !default;
$carrotOrange: #ef8f1e !default;
$light-orange: #f8c890 !default;
$yellow: #f7f8c6 !default;
$green: #5ea541 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$transluscent: rgba(222, 222, 222, 0.8);

$nyc-blue: #4c8ad8 !default;
$nyc-blue-lighten-5: lighten($nyc-blue, 5%);
$nyc-blue-lighten-10: lighten($nyc-blue, 10%);
$nyc-blue-lighten-15: lighten($nyc-blue, 15%);
$nyc-blue-lighten-20: lighten($nyc-blue, 20%);
$nyc-blue-lighten-25: lighten($nyc-blue, 25%);
$nyc-blue-lighten-30: lighten($nyc-blue, 30%);

$nyc-orange: #f8a35e !default;
$nyc-crimson: #dfcdd3 !default;
$tab-blue: #d8e3f1 !default;
$logo-gray: #f2f2f2 !default;
$property-blue: #a3bec8 !default;
$housing-purple: #96a6d8 !default;
$light-gray: #a39e9e !default;

// Summary Card Colors
$acris-yellow: #f6f7c6;
$hpd-orange: #fce7d0;
$dob-red: #deccd2;
$summary-blue: #86b1e5;
$custom-turqouise: #afeeee;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'white': $white,
    'gray': $gray-600,
    'gray-dark': $gray-700,
    'transluscent': $transluscent,
  ),
  $colors
);

$primary: $gray-900 !default;
$secondary: $danube !default;
$secondary-light: lighten($secondary, 10%);
$success: $green !default;
$info: $blizzard !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;

$text-link: $congress-blue;

$tab-dark: $rhino;
$tab-dark-hover: $gray-200;
$tab-dark-active: $rhino;

$tab-primary: $tab-blue;
$tab-primary-hover: $paleCornflowerBlue;
$tab-primary-active: $secondary;

$tab-secondary: $light-orange;
$tab-secondary-hover: $texasRose;
$tab-secondary-active: $carrotOrange;

$tab-disabled: $gray-300;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
  ),
  $theme-colors
);

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900 !default;
$yiq-text-light: $white !default;

$component-active-color: $white !default;
$component-active-bg: theme-color('primary') !default;

// Body
//
// Settings for the `<body>` element.

$body-bg: $white !default;
$body-color: $gray-900 !default;
