@import 'shared/styles/bootstrap/_variables.scss';

.inner-loader {
  text-align: center;
  background-color: $tab-primary;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  /*Location indicator */
  .location_indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .location_indicator:before,
  .location_indicator:after {
    content: '';
    position: absolute;
  }

  .location_indicator:before {
    width: 20px;
    height: 20px;
    border-radius: 100% 100% 100% 0;
    box-shadow: 0px 0px 0px 10px rgba(255, 255, 255, 1);
    -webkit-animation: mapping 1s linear infinite;
    -moz-animation: mapping 1s linear infinite;
    animation: mapping 1s linear infinite;
    -webkit-transform: rotate(-46deg);
    -moz-transform: rotate(-46deg);
    transform: rotate(-46deg);
  }

  .location_indicator:after {
    width: 30px;
    height: 10px;
    border-radius: 100%;
    left: -14px;
    background-color: rgba(255, 255, 255, 0.2);
    top: 50px;
    z-index: -1;
  }

  @keyframes mapping {
    0% {
      top: 0;
    }
    50% {
      top: -10px;
    }
    100% {
      top: 0;
    }
  }
}
