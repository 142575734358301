@import 'shared/styles/bootstrap/_variables.scss';




.property-summary-body.row {
  box-sizing: border-box;
  margin-left: -30px;
  margin-right: -30px;

  @include media-breakpoint-up(sm) {
    margin-left: -30px;
    margin-right: -30px;
  }

  @include media-breakpoint-up(lg) {
    margin-left: -25px;
    margin-right: -25px;
  }

  @include media-breakpoint-up(xl) {
    margin-left: -75px;
    margin-right: -75px;
  }

  h5 {
    color: $gray-800;
    font-weight: bold;
    text-transform: uppercase;
  }

  p {
    color: $gray-800;
  }
  .profile-summary__geography {
    a {
      color: $black;
      font-weight: normal;
      text-decoration: none;
    }
  }
  .profile-summary__geography:hover {
    a {
      color: $secondary;
      font-weight: bold;
      text-decoration: underline;
    }
  }
}



  .lookup-internal-link{
    color: #004f9e !important;
    text-decoration: underline;
    transition: .25s ease-in-out;
  }
    .lookup-internal-link:hover{
    color: #001e3b !important;
    text-decoration: underline;
     transition: .25s ease-in-out;
  }

    .property-section {    
    background-color: #a2cffc !important}
