@import 'shared/styles/bootstrap/_variables.scss';

.spinner-loader__container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.spinner-loader__container--inline {
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: center;
  margin-left: 2px;
}

.spinner-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 50px;
  height: 50px;
}
.spinner-loader div {
  box-shadow: 1px 1px 4px $white;
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 50px;
  height: 50px;
  border: 5px solid $secondary;
  border-radius: 50%;
  animation: spinner-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $primary transparent transparent transparent;
}
.spinner-loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.spinner-loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.spinner-loader div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes spinner-loader {
  0% {
    transform: rotate(0deg);
    box-shadow-color: 0px 0px 1px $white;
  }
  100% {
    transform: rotate(360deg);
    box-shadow: 0px 0px 1px $secondary;
  }
}
