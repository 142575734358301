@import 'shared/styles/_variables.scss';

.lookup-links {
  @extend %p3-font-size;

  .lookup-links__label {
    color: $black;
    font-weight: bold;
  }

  .lookup-links__link-row {
    display: flex;
    flex-direction: column;
    text-align: left;

    > * {
      font-weight: bold;
      margin-right: 20px;
      margin-bottom: 10px;
    }
  }
}
