@import 'shared/styles/_variables.scss';

.map {
  height: 100%;
  width: 100%;
}

.leaflet-pane,
.leaflet-top,
.leaflet-left,
.leaflet-bottom,
.leaflet-right {
  z-index: 1;
}

.map-loader {
  background-color: rgba(1, 1, 1, 0.2);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.leaflet-popup-content {
  @extend %p3-font-size;
  display: flex;
  flex-direction: column;
}

.leaflet-popup-title {
  margin-bottom: 10px;
}
