.text-light-gray {
  color: $light-gray !important;
}

.text-dark-gray {
  color: $gray-700 !important;
}

.text-black,
%text-black {
  color: $black !important;
}
@each $key, $value in $grays {
  .text-gray-#{$key} {
    color: $value !important;
  }
}

.custom-turqouise {
  background-color: $custom-turqouise;
}

.building-pink {
  background-color: $pink;
}

.tab-color--primary {
  background-color: $tab-primary;
}

.tab-color--secondary {
  background-color: $tab-secondary;
}

.acris-yellow {
  background-color: $acris-yellow;
}

.hpd-orange {
  background-color: $hpd-orange;
}

.dob-red {
  background-color: $dob-red;
}

.summary-blue {
  background-color: $summary-blue;
}

.bg-nyc-blue-lighten-5 {
  background-color: $nyc-blue-lighten-5;
}

.bg-nyc-blue-lighten-10 {
  background-color: $nyc-blue-lighten-10;
}

.bg-nyc-blue-lighten-15 {
  background-color: $nyc-blue-lighten-15;
}

.bg-nyc-blue-lighten-20 {
  background-color: $nyc-blue-lighten-20;
}

.bg-nyc-blue-lighten-25 {
  background-color: $nyc-blue-lighten-25;
}

.bg-nyc-blue-lighten-30 {
  background-color: $nyc-blue-lighten-30;
}

.scale-3 {
  transform: scale(3);
}

.scale-5 {
  transform: scale(5);
}

.scale-10 {
  transform: scale(10);
}

label.toggle-link.btn,
label.toggle-link.btn:active,
label.btn-primary:not(:disabled):not(.disabled):active {
  background: transparent;
  box-shadow: none;
  color: $secondary;
  border: 2px solid transparent;
  border-radius: 10px !important;
  cursor: pointer;
  font-size: 0.9rem;
  margin-right: 2px;
  margin-bottom: 2px;
  outline: 0;
  text-decoration: underline;
  max-width: 180px;
}
// Light toggles
label.toggle-link.btn.light,
label.toggle-link.btn.light:active,
label.btn-primary:not(:disabled):not(.disabled).light:active {
  color: $info;
}

label.toggle-link.btn.disabled {
  cursor: initial;
}

@media (hover: hover) {
  label.toggle-link.btn:hover:not(.active):not(.disabled) {
    background: transparent;
    border: 2px dashed transparent;
    border-bottom: 4px double $info;
    border-radius: 0px !important;
    outline: 0;
    cursor: pointer;
    margin-right: 2px;
    margin-bottom: 2px;
    box-shadow: none;
    text-decoration: none;
  }

  // light hover
  label.toggle-link.btn:hover:not(.active).light {
    border-bottom: 4px double darken($info, 10%);
  }
}

label.toggle-link.active.btn:not(.disabled),
label.toggle-link.focus.active.btn-outline-primary:not(.disabled),
label.toggle-link.active.btn-outline-primary:not(.disabled),
label.toggle-link.focus.active.btn-primary:not(.disabled),
label.toggle-link.active.btn-primary:not(.disabled) {
  background-color: transparent;
  color: $gray-500;
  border: 2px dashed $gray-500;
  box-sizing: border-box;
  outline: 0;
  cursor: pointer;
  text-decoration: none;
}

label.toggle-link.active.btn.light.active,
label.toggle-link.focus.active.btn-outline-primary:not(:disabled).light.active,
label.toggle-link.active.btn-outline-primary:not(:disabled).light.active,
label.toggle-link.focus.active.btn-primary:not(:disabled).light.active,
label.toggle-link.active.btn-primary:not(:disabled).light.active {
  color: $white;
  border: 2px dashed $white;
}

.h-0 {
  height: 0% !important;
}
.w-0 {
  width: 0% !important;
}

.blank-button,
%blank-button {
  cursor: pointer;
  box-shadow: none;
  border: none;
  background: transparent;
  outline: none;

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.text-link,
%text-link {
  @extend %blank-button;
  color: $text-link;
  font-weight: bold;
  padding: 0;

  &:hover,
  &:focus {
    color: darken($text-link, 10%);
  }
}

%text-button {
  box-shadow: none;
  border: none;
  outline: none;
}

.text-button--default {
  @extend %text-button;
  @extend %text-link;
  @extend %p2-font-size;
}

.text-button--smaller {
  @extend %text-button;
  @extend %text-link;
  @extend %p4-font-size;
}

.icon-button--right {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-left: 10px;
  }
}
