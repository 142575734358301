@import 'shared/styles/_variables.scss';

table.table,
.base-table,
.nested-table {
  width: 100%;

  @include media-breakpoint-up(xl) {
    width: fit-content;
    max-width: 100%;
  }

  table {
    background-color: $white;
  }

  thead th {
    outline: none;
    padding-top: 10px;
    vertical-align: top;
  }

  thead th,
  tbody td {
    @extend %p3-font-size;
    vertical-align: middle;

    input {
      height: 30px;
      padding: 0 10px;
    }
  }

  tbody tr {
    // cursor: pointer;
    // remove animation expanding
    transition: all 0s linear;

    * {
      transition: all 0s linear;
    }
  }

  .sortable {
    span {
      font-size: 20px;
    }
  }

  .sortable:hover {
    span.order-4 {
      color: $black;
      font-weight: bold;

      &::before,
      &::after {
        opacity: 1;
      }
    }
  }

  // pagination buttons
  .page-item {
    margin-right: 5px;
  }

  .table-sm td {
    padding: 5px;
  }
  tbody tr.expanding-row td:not(.table-column--active) {
    background-color: $gray-200;
    margin: 0;
    padding: 0;
  }
  tbody tr.expanding-row {
    margin: 0;
    padding: 0;
  }

  table:not(.no-expand) {
    tr:not(.expanding-row) td:not(.table-column--active):not(.react-bs-table-no-data):not(.nested-table-column):before {
      // content: '(+) ';
    }

    // Fixes bootstrap table 2 behavior,
    // do not expand row on all clicks!
    // only clicks to .expandable-cell
    td,
    tbody tr {
      // pointer-events: none;
    }

    td a,
    .expandable-cell {
      pointer-events: all;
    }
  }

  @media (hover: hover) {
    table.no-expand {
      tbody tr:hover {
        background-color: $secondary;
        color: white;
      }
    }
    // tr:not(.expanding-row) td:not(.table-column--active):not(.react-bs-table-no-data):hover {
    //   background-color: $secondary;
    //   color: white;
    // }
  }

  .table-column--active {
    background-color: $secondary;
    color: white;
    // text-overflow: inherit;
    // white-space: normal;
    // overflow-x: hidden;
    // active
  }

  .table-column--active:before {
    // content: '(-) ';
  }

  .filter {
    min-width: initial;
  }

  .table-row--collapsed * {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
  }

  .table-row--expanded * {
    overflow-x: hidden;
  }

  .row-expansion-style,
  .reset-expansion-style {
    padding: 0;
  }

  // eliminates table animation
  .row-expand-slide-appear-active,
  .row-expand-slide-exit-active {
    transition: none;
  }

  .table-row--nested-bumper {
    padding-left: 10px;
    background-color: $gray-700;

    @include media-breakpoint-up(sm) {
      padding-left: 20px;
    }
  }

  .table-row--expanded .table-column--description {
    display: block;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .table-row--expanded .table-column--description:after {
    text-transform: capitalize;
    display: block;
    content: '(below)';
    font-weight: bold;
  }

  .page-link {
    padding: 5px 10px;
  }

  .dropdown-toggle {
    padding: 5px 10px;
  }

  .base-table__select-filters {
    // background-color: $secondary;
    align-items: center;
    justify-items: center;
    display: flex;
    flex-wrap: nowrap;
    // margin-right: 20px;
    flex: 0 0 auto;

    & > * {
      margin-right: 20px;
    }
  }

  .base-table__select-filters__container {
    flex: 0 0 auto;
    // margin-bottom: 10px;
  }

  .text-filter,
  .text-filter:focus {
    border: 1px solid $gray-400;
    font-size: 12px;
    line-height: 16px;
    margin-top: 5px;
    margin-bottom: 0;
    padding-left: 5px;
    height: 20px;
    border-radius: 0;
  }
}

.base-table__header-body-wrapper {
  overflow-x: auto;
  // width: min-content;
}

.base-table__header-body-inner-wrapper {
  width: min-content;
}

.base-table-header__wrapper {
  // max-width: 1044px;
  width: max-content;
  position: sticky;
  left: 0;
}

.base-table .base-table {
  .expanded-row {
    background-color: $secondary;
  }
}
.csv-button.btn {
  cursor: pointer;
}

.hide-filter {
  input,
  select {
    display: none;
  }
}

.table-filter-button-group {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  // padding: 10px;
  // padding-bottom: 0;
  // max-height: 100px;
  // max-width: 220px;

  .table-filter-button {
    margin-right: 5px;
    min-width: 60px;
    max-width: 100px;
  }
}

// Size per page dropdown
.dropdown-menu {
  .dropdown-item {
    padding-left: 0;
    a {
      display: block;
      height: 100%;
      padding-left: 15px;
      width: 100%;
    }
  }
}
