@import 'shared/styles/_variables.scss';

.ownership-section {
  .ownership-section__update-section {
    margin-bottom: 20px;
  }

  .ownership-section__contacts-section {
    margin-bottom: 20px;
  }

  .ownership-section__hpdcontact-row {
    // color: white;
    display: flex;
    width: calc(100% - 40px);
  }

  .ownership-section__bottom-section {
  }

  .ownership-section__bottom-value {
    margin-bottom: 20px;
  }
}
