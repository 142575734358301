
/* 
Overriding bootstrap switch toggle for save custom search 
*/
.save-custom-search__notifications .custom-control-input:checked ~ .custom-control-label::before {
    color: black;
    background-color: #1465b7;
}

.save-custom-search__notifications .custom-control-label::before, .custom-control-label::after {
    top: 3px !important;
}

.save-custom-search__notifications .custom-switch .custom-control-label::before {
    left: -2rem !important;
    width: 33px !important;
    height: 18px;
    pointer-events: all;
    border-radius: 0.5rem;
}

.save-custom-search__notifications .custom-switch .custom-control-label::after {
    top: 4px !important;
    left: calc(-2.25rem + 4px) !important;
    width: 20px !important; 
    height: 1rem !important;
}

// district
.district-dashboard-show .district-dashboard-show__housing-type-section {
    margin-top: 30px;
}
// Filters
.lookup-profile-summary__section-header h5 {
    font-size: 17px;
    line-height: 19px;
}
.lookup-show.layout-width-wrapper .lookup-address-display__title p {
    cursor: default;
}
.lookup-address-display .pencil-icon {
    height: auto;
    width: 15px;
}
.lookup-address-display p.address {
    display: inline-block;
}
.lookup-address-display__button.lookup-address-display__edit-button {
    background: transparent;
    border: none;
    margin-left: 0;
    padding-left: 6px;
    margin-top: 0 !important;
    padding-top: 0;
}
.lookup-show .lookup-show__top-row {
    flex-direction: row-reverse;
}
.bookmark-button__container {
    text-align: right;
}

// Mobile Nav and Hamburger Menu Code

.mobile-nav .nav-tabs {
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
    width: 100%;
border-bottom: none !important;
}

.mobile-nav .sub-header__nav-tab {
    width: 100% !important;}

.mobile-nav .sub-header__wrapper--top__links {
display: flex;
flex-direction: column;
    width: 100%;
    padding: 0px 0 10px 0px;
justify-content: center;
align-items: center;
margin-top: 10px;

}

.mobile-nav {
    display:none;
    transition: .25s ease-in-out;
    opacity: 0;
    box-shadow: 2px 2px 5px rgb(0 0 0 / 20%);
}

 .layout-width-wrapper {
    box-sizing: border-box;
    margin: 0 auto 6px !important;
 }

 .sub-header .sub-header__brand .navbar-brand {
    margin-right: 0;
    margin-top: 4px;
    padding-bottom: 0px;
 }  
.hamburger-text {
    position: absolute;
    left: 1px;
    top: 26px;
    font-size: 13px;
    color: #014c8d;
    font-weight: 800;
} 

.location-section {background-color: #a2cffc !important;}

 .mobile-nav .sub-header__logo {
    display: block !important;
    height: auto;
    width: 100% !important;
    margin: 0 auto !important;
    display: block !important;
    max-width: 180px !important;
}

.mobile-nav .sub-header__wrapper--top__links .nav-item {
    padding-left:0 !important;
    padding-right:0 !important
}


.ham-text-2 {display:none}
.mobile__logo {
    position: absolute; 
    top:18px;
    right:16px
}

.mobile-nav .sub-header__nav-tab {
    height: 50px;
        padding-top: 11px;
}

.mobile-nav .tab--dark-inverse.active {
    border-top: 1px solid #609bd6;
    border-bottom: 1px solid #609bd6;
    background: #1465b7;
}

.mobile-nav .sub-header__nav-tab {  
align-items: center;
font-size: 16px;
border-radius: 0 !important;
border-left:none;
border-right:none;
border-top: 1px solid #424a54;
border-bottom: 1px solid #424a54;
}

.mobile-nav .nav-tabs {
  border-bottom: none !important;
      height: fit-content;
}

.mobile-nav-hamburger {
display:none;
}

.mobile-nav .nav-item {
margin: 5px auto 0;
  font-size: 16px;
      text-align: center;
}

.mobile-nav .sub-header__wrapper--top__links .nav-item{
    margin: 0px auto 0;
    font-size: 15px;
    text-align: center;
}

.css-17zmrss {
    width: 220px !important;
}

.layout__left-column a {
    color: #ffffff;
    text-decoration:underline;
}



