@import 'shared/styles/_variables.scss';
$sub-header-padding-top: 12px;
.sub-header {
  background-color: $white;

  .auth-user {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: initial;
    }
  }

  .navbar {
    padding: 0;
  }

  .sub-header__brand {
    // margin: 0 auto;
    margin-left: auto;
    margin-right: 0;

    @include media-breakpoint-up(sm) {
    }

    .navbar-brand {
      margin-right: 0;
    }
  }

  .sub-header__wrapper--top,
  .sub-header__wrapper--bottom {
    @include media-breakpoint-up(xl) {
      padding: 0;
    }
  }

  .sub-header__wrapper--top {
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    text-align: right;
    width: 100%;

    @include media-breakpoint-up(sm) {
      // flex-wrap: nowrap;
    }

    @include media-breakpoint-up(md) {
      flex-wrap: wrap;
    }

    .navbar-brand {
      align-items: center;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      width: 100%;

      @include media-breakpoint-up(sm) {
        max-width: 300px;
        padding: $sub-header-padding-top 0 40px;
      }

      @include media-breakpoint-up(md) {
        padding: $sub-header-padding-top 40px 20px;
        padding-left: 0;
        // width: 300px;
      }
    }
  }
  .sub-header__logo {
    display: block;
    height: auto;
    width: 100%;
    margin: 0 auto;
    display: block;
    max-width: 180px;

    @include media-breakpoint-up(md) {
      width: 100%;
    }
  }

  .sub-header__wrapper--top__links {
    align-self: flex-start;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
      margin-left: auto;
    }

    .nav-item {
      @extend %p3-font-size;
      padding: 0;
      white-space: pre;
      width: 100%;
      padding: $sub-header-padding-top 15px 10px;

      &:last-child {
        margin-right: 0;
      }

      button {
        padding: 0;
      }

      @include media-breakpoint-up(sm) {
        margin-right: 20px;
        width: auto;
      }
      @include media-breakpoint-up(md) {
        margin-right: 0px;
      }
    }

    .nav-item:last-child {
      padding-right: 0;
    }
  }

  .sub-header__wrapper--bottom {
    align-items: flex-end;
    width: 100%;

    .navbar-nav {
      flex-wrap: wrap;

      @include media-breakpoint-up(xl) {
        flex-wrap: nowrap;
      }
    }

    .nav-tabs {
      border: 0;
      width: 100%;
    }
  }

  .sub-header__auth-row {
    padding: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;

    .col:first-child {
      @include media-breakpoint-up(sm) {
        text-align: left;
      }
      @include media-breakpoint-up(md) {
        text-align: right;
      }
    }
  }

  .sub-header__auth-row,
  .sub-header__nav-row {
    @include media-breakpoint-up(sm) {
      justify-content: flex-end;
    }
  }

  .sub-header__nav-tab {
    @extend %p3-font-size;
    border: 1px solid $dark;
    box-sizing: border-box;
    padding: 1rem 15px;
    text-decoration: none;
    flex: 0 0 100%;
    white-space: pre;
    margin-bottom: -1px;

    @include media-breakpoint-up(sm) {
      align-items: center;
      border-radius: 6px;
      display: flex;
      flex: 0 1 auto;
      margin-right: 4px;
      justify-content: center;
      height: 40px;

      padding: 1rem 1rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 1rem 2rem;
    }
  }

  .sub-header__nav-line {
    margin: 0;
    border-color: $gray-300;
    border-width: 2px;
  }
}
