.save-search-button__container {
    stroke: black;
    stroke-width: 20 !important;
    cursor: pointer;

    svg {
        margin-right: 5px;
    }

    .save-search-button__icon_inactive path {
        fill: white !important;
    }
    
    .save-search-button__icon_active path {
        fill:  #424a54 !important;
    }
}

.save-search-button__container:hover {
    text-decoration: underline;
    
}


