table {
  .expandable-cell {
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        background-color: $secondary;
        color: white;
      }
    }
  }

  &:not(.fluid-table) {
    border: 0;
    @include media-breakpoint-up(xs) {
      display: block;
      width: 100%;
    }

    @include media-breakpoint-up(xl) {
      width: fit-content;
    }
  }

  td {
    @include media-breakpoint-up(xs) {
      max-width: 200px;
    }

    @include media-breakpoint-up(md) {
      max-width: 300px;
    }
  }
}
