@import 'shared/styles/_variables.scss';

.loading {
  position: relative;
  transition: 'transform 5000 ease-in-out';

  .loading__logo-container {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-bottom: 20px;

    img {
      margin-bottom: 0;
    }
  }
  img {
    // height: auto;
    width: auto;
    max-height: 60px;
    @include media-breakpoint-up(sm) {
      max-height: 100px;
    }
  }
  .loading__background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .loading__wrapper {
    position: relative;
  }
  .loading__requests {
    min-width: 300px;
    z-index: 2;
  }
}
