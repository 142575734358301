@import 'shared/styles/_variables.scss';

.rentstabilization-section {
}

.rentstabilization-section__top-section {
  margin-bottom: 20px;
}

.renstabilization-section__table-header {
  margin-bottom: 10px;

  h6 {
    @extend %p3-font-size;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.renstabilization-section__table {
  &:not(.fluid-table) {
    overflow-x: hidden;
  }

  .renstabilization-section__table {
  }

  .renstabilization-section__table--row {
    display: flex;
    flex-direction: row;
    padding-left: 20px;

    th {
      display: block;
      padding: 2px 0;
      padding-right: 40px;
    }

    td {
      padding: 2px 0;
    }
  }
}
