@import 'shared/styles/_variables.scss';

.search-bar {
  z-index: 501;

  .home-search-bar {
    &.form-control:focus,
    &.form-control:focus-within {
      border-bottom: 1px solid rgb(181, 206, 239); // Bootstrap style to undo override from .form-control
    }

    input.form-control {
      padding-right: 50px;
    }
  }

  // Legacy style
  .xl-form-control {
    border-bottom: 2px solid $secondary;

    &.form-control:focus,
    .form-control:focus-within {
      border-bottom: $input-border-focus-default;
    }
  }
}
