@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }

  * {
    -webkit-print-color-adjust: exact;
  }
}
