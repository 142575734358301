@import 'shared/styles/_variables.scss';

.request-access-modal {
  &__text {
    @extend %p3-font-size;
  }

  &__text, a {
    cursor: pointer;
  }

  &__link {
    a:hover {
      text-decoration: underline !important;
      cursor: pointer;
    }
  }
}
