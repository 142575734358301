@import 'shared/styles/_variables.scss';

.sentence-dropdown {
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  outline: none;
  text-decoration: underline;
  padding-left: 2px;
  padding-right: 4px;

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-menu,
  .dropdown-item {
    padding-left: 5px;
  }

  &:hover,
  &:focus {
    color: $secondary;
  }
}
