// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg: 1.5 !default;
$line-height-sm: 1.5 !default;

$border-width: 1px !default;
$border-color: $gray-300 !default;

$border-radius: 0.25rem !default;
$border-radius-lg: 0.3rem !default;
$border-radius-sm: 0.2rem !default;

$rounded-pill: 50rem !default;

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 3,
    ),
    6: (
      $spacer * 4,
    ),
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto,
  ),
  $sizes
);

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grid-breakpoints: map-merge(
  (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1900px,
  ),
  $grid-breakpoints
);

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$container-max-widths: map-merge(
  (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
  ),
  $container-max-widths
);

@include _assert-ascending($container-max-widths, '$container-max-widths');

$mini-spacing: 5px;
$tiny-spacing: 10px;
$small-spacing: 20px;
$medium-spacing: 40px;
$large-spacing: 80px;
