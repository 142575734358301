@import 'shared/styles/_variables.scss';

.dashboard-results-editor {
  border: 1px solid $dark;
  border-radius: 2px;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;

  &__sentence-section {
    @extend %p3-font-size;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 32px;
    margin-bottom: 10px;
  }

  .amount-filter-input {
    display: inline-block;
    font-weight: bold;
    text-decoration: underline;

    .form-group {
      display: flex;
    }

    .form-control {
      border: 0;
      text-decoration: underline;
      width: 30px;
      padding: 0;
      margin-left: 4px;
      margin-right: -4px;
      text-align: center;
      height: 100%;
    }

    .form-control,
    button[type='submit'] {
      display: inline-block;
    }
  }

  .amount-filter-input {
    height: 20px;
    margin-right: 0;

    // &:hover,
    &:focus,
    &:focus-within,
    &.active {
      outline: none;
      margin-right: 0;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
      padding: 0;
      margin-left: 4px;
    }
  }

  &__inner-wrapper {
    display: flex;
    align-items: center;

    > div:first-child {
      margin-right: 16px;
    }
  }

  &__group {
    display: flex;

    > :first-child {
      margin-right: 5px;
    }
  }

  &__label {
    @extend %p3-font-size;
    color: $black;
    display: block;

    @include media-breakpoint-up(lg) {
      display: inline-block;
    }
  }

  &__value {
    @extend %p3-font-size;

    font-weight: bold;
  }
}
