@import 'shared/styles/bootstrap/_variables.scss';
// expanded row
.row-expansion-style {
  background-color: $white;
}

.expanded-row {
  border: 0;
  max-width: 300px;
  position: sticky;
  left: 0;
  display: block;
  background-color: $white;

  @include media-breakpoint-up(sm) {
    max-width: 500px;
  }
  .expanded-row--wrapper {
    width: 100%;
    background-color: $white;
    margin-left: 10px;
    @include media-breakpoint-up(sm) {
      margin-left: 20px;
    }
  }

  p {
    margin: 0;
    padding: 10px 40px;
    padding-left: 0;
  }
}
