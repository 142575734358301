@import 'shared/styles/bootstrap/_variables.scss';

.condition {
  min-height: 100px;

  .condition {
    .form-row__connection-container:not(.condition-connection) {
      .form-row__connection {
        border-color: $secondary;
      }

      .form-row__connection:first-child {
        border-bottom: 5px double $secondary;
      }
    }
    .condition-connection {
      border-bottom: none;
    }
  }

  .switch-condition-row {
    margin: 0 auto 20px;
    margin-left: 25%;

    .dropdown-menu {
      min-width: 80px;
      max-width: 80px;
      padding-left: 10px;
    }
  }

  .new-filter-row {
    font-weight: bold;
    justify-content: flex-start;

    .add-filter {
      margin-left: 0;
      flex: 0 1 auto;
      width: auto;
    }

    .new-filter__either {
      margin: 0 auto 0 calc(25% - 12px);
    }

    .btn-group {
      width: 100%;
    }
  }

  .control-button {
    margin: 0.25rem;
  }

  .control-button.dropdown.btn-group {
    margin: 0;
    padding: inherit 0;
  }

  .control-button.add-filter,
  .control-button.remove-add-filter {
    margin-left: 0;
  }
  .control-button,
  .control-button.btn,
  .control-button .dropdown-toggle,
  .control-button .dropdown-menu {
    font-weight: bold;
    min-width: 80px;
    max-width: 80px;
    margin: 0;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 22px;
    line-height: 38px;

    @include media-breakpoint-up(sm) {
      // min-width: 100px;
      // max-width: 120px;
      font-size: 25px;
      line-height: 38px;
    }
  }
  .control-button .dropdown-menu {
    background-color: lighten($success, 20%);
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
