@import 'shared/styles/bootstrap/_variables.scss';

.print-layout {
  button:not(.no-print),
  button.result-card:not(.no-print),
  button.btn-primary:not(.no-print):hover,
  button.bg-light:not(.no-print):focus,
  button.bg-light:not(.no-print):hover,
  .result-card.card.btn-primary:not(:disabled):not(.no-print):hover {
    box-shadow: none;
    transition: none;
    transform: initial;
    border: 1px solid $black;
    cursor: initial;
    color: initial;
    background-color: $gray-100 !important;
    padding: 0 7px;

    p,
    h4,
    h2,
    h5,
    small,
    span {
      color: initial;
    }
  }
}
