@import 'shared/styles/_variables.scss';

.district-filter-section {
  .district-filter-section__title {
    @extend %p2-font-size;
    color: $black;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  .district-filter-section__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 40px;
    margin-bottom: 10px;
  }

  .dashboard-filter-section__toggle {
    display: flex;
    align-items: center;
    justify-content: center;

    .info-modal-button {
      margin-left: 10px;
    }
    label {
      min-width: 50px;
    }
  }
}
