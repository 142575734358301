@import 'shared/styles/_variables.scss';

.modal-md {
  max-width: 400px !important;
}

.modal-header {
  font-family: $font-family-primary;
}

.modal-body {
  @extend %p1-font-size;
}

.modal-sources {
  @extend %p3-font-size;
}
