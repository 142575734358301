@import 'shared/styles/_variables.scss';

.user-register-form {
  .form-label {
    @extend %p3-font-size;
    color: $black;
    font-weight: bold;
  }

  &__text {
    @extend %p3-font-size;
    color: $black;
  }
}
