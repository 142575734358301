@import 'shared/styles/_variables.scss';

.table-header {
  margin-bottom: 20px;

  .table-header__title-row {
    align-items: center;
    display: flex;
  }

  .table-header__title {
    margin-right: 10px;
  }

  .table-header__dataset-info {
    margin-bottom: 10px;
  }

  .table-header__subcopy {
    @extend %p3-font-size;

    div {
      margin-bottom: 10px;
    }
  }

  .info-modal-button {
    margin-right: 20px;
  }
}
