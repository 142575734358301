@import 'shared/styles/_variables.scss';

.property-section {
  @extend %p3-font-size;
  background-color: $secondary;
  color: $black;
  padding: $lookup-sidebar-padding;
}

.lookup-profile-summary__section-header {
  align-items: center;
  background-color: $dark;
  color: $white;
  cursor: pointer;
  display: flex;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px $lookup-sidebar-padding;

  h5 {
    font-weight: bold;
  }

  svg {
    margin-left: auto;
  }

  &:hover,
  &:focus {
    background-color: $gray-400;
    outline: none;
  }
}

.lookup-profile-summary__info {
  display: inline-block;
  margin-left: 8px;
}

.lookup-profile-summary__expandable-icon {
  color: $white;
  position: absolute;
  right: $lookup-sidebar-padding;
  top: 10px;
  pointer-events: none;
}

.lookup-profile-summary__group {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.property-summary__table-header,
.property-summary__table {
  padding: 0;
}

.profile-summary-body__label {
  @extend %p3-font-size;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  color: $black;
  font-weight: bold;
  margin: 0;
  margin-right: auto;
}

.profile-summary-body__value {
  @extend %p3-font-size;
  color: $black;
  display: block;
  font-weight: normal;
}

li.profile-summary-body__value {
  display: list-item;
  margin-bottom: 0;
}

.lookup-profile-summary.card {
  border: 0;
  background-color: transparent;
}

.lookup-profile-summary {
  .lookup-profile-summary__body {
  }

  .table-header {
    color: $white;
  }

  .lookup-profile-summary__bottom-info {
    margin: 0 -15px;
  }

  .expandable-section:first-child {
    .lookup-profile-summary__expandable-icon {
      // top: 40px;
    }
  }
}
