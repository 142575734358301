@import 'shared/styles/_variables.scss';

.amount-result-filter-card--container {
  // align-items: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  // padding-right: 40px;

  .text-link {
    @extend %p3-font-size;
    text-align: left;
    font-style: italic;
  }
}

.amount-result-filter-card__info {
  display: inline;
  margin-left: 5px;
}

.amount-result-filter-card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.amount-result-filter-card__label {
  @extend %p2-font-size;
  color: $black;
  font-weight: bold;
  margin-bottom: 0;
}

.amount-result-filter-card__filter {
  @extend %p3-font-size;
  color: $text-muted;
  margin-bottom: 20px;
  padding-right: 40px;

  &.active {
    color: $black;
  }

  .form-group {
    display: inline;
    position: relative;
  }

  input.form-control {
    border: none;
    border-bottom: 1px solid $gray-300;
    display: inline;
    max-width: 60px;
    margin-right: 5px;
  }

  .form-text {
    position: absolute;
    bottom: -30px;
  }

  .amount-filter-input {
    display: inline;
    outline: none;
  }
}
