@import 'shared/styles/_variables.scss';

.advanced-search-sentence-editor {
  border: 1px solid $dark;
  border-radius: 2px;
  box-sizing: border-box;
  margin-bottom: 20px;
  padding: 16px;
  display: flex;
  flex-direction: column;

  &__search {
    min-width: 200px;
    margin-left: 30px;
    text-align: right;
  }

  &__sentence-section {
    @extend %p3-font-size;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 32px;
  }

  &__disclaimer-section {
    display: flex;
    align-items: left;
    justify-content: flex-start;
    @extend %p4-font-size;
  }

  .housing-type-dropdown,
  .date-dropdown,
  .condition-dropdown,
  .amount-filter-input {
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    text-decoration: underline;

    .dropdown-toggle::after {
      display: none;
    }

    .dropdown-menu,
    .dropdown-item {
      outline: none;
      padding-left: 5px;
    }

    .form-group {
      display: flex;
    }

    .form-control {
      border: 0;
      text-decoration: underline;
      width: 60px;
    }

    .form-control,
    button[type='submit'] {
      display: inline-block;
    }

    &:hover,
    &:focus {
      color: $secondary;
    }
  }

  .amount-filter-input {
    height: 32px;
    margin-right: -28px;

    // &:hover,
    &:focus,
    &:focus-within,
    &.active {
      margin-right: 0;
    }
  }

  &__inner-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  &__buttons {
    flex: 100%;
    margin-left: auto;
    margin-top: 20px;
    @include media-breakpoint-up(md) {
      flex: none;
      margin-top: 0;
    }
  }

  &__group {
    display: flex;
    margin-right: 16px;
    
    > :first-child {
      margin-right: 5px;
    }
  }

  &__label {
    @extend %p3-font-size;
    color: $black;
    display: block;

    @include media-breakpoint-up(lg) {
      display: inline-block;
    }
  }

  &__value {
    @extend %p3-font-size;

    font-weight: bold;
  }
}
