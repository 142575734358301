@import 'shared/styles/_variables.scss';

.lookup-sidebar {
  white-space: nowrap;
  overflow: overlay;

  @include media-breakpoint-down(xs) {
    margin-bottom: 20px;
  }

  @include media-breakpoint-up(sm) {
    margin-top: -1px;
    margin-left: -1rem;
  }

  @include media-breakpoint-up(xl) {
    margin-left: -2rem;
  }

  &.full-bleed--mobile {
    background-color: $dark;
    overflow: visible;
    position: relative;
    transition: width 0.4s ease-in-out, flex-basis 0.4s ease-in-out;
    flex-basis: 0px;

    @include media-breakpoint-up(sm) {
      width: 1rem !important;
    }

    &.open {
      white-space: pre-wrap;

      flex: 0 0 100%;
      width: calc(100% + 2rem);

      @include media-breakpoint-up(sm) {
        flex: 0 0 $lookup-sidebar-width;
        width: $lookup-sidebar-width;
      }
    }
  }

  .lookup-sidebar__wrapper {
    position: sticky;
    top: 0;
    overflow: visible;
  }

  .lookup-sidebar__body {
    overflow: hidden;
    padding: 0;
    overflow-x: visible;
    overflow-y: overlay;

    /* width */
    &::-webkit-scrollbar {
      width: 8px;
      box-shadow: none;
      background: $secondary;
    }
  }

  .lookup-sidebar__header {
    // padding: 30px 0;

    h4 {
      color: $white;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .lookup-sidebar__content {
    @include media-breakpoint-up(sm) {
      height: calc(100vh);
      min-height: 0;
      overflow-x: hidden;
      overflow-y: overlay;
      padding-bottom: 0;

      @include media-breakpoint-up(sm) {
        padding-bottom: 200px;
      }
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: none;
      box-shadow: inset 0 0px 10px transparent;
    }

    &::-webkit-scrollbar-track-piece {
      box-shadow: none;
      background: $secondary;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $dark;
      border-radius: 10px;
      height: 30px;
      width: 8px;
    }

    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }

    &::-webkit-scrollbar-corner {
      background: $secondary;
    }
  }

  .lookup-sidebar__toggle {
    background-color: $dark;
    color: white;
    cursor: pointer;
    display: none;
    position: absolute;
    right: -20px;
    width: 20px;
    height: 80px;
    top: 8px;
    z-index: 1;

    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

    @include media-breakpoint-up(sm) {
      display: flex;
    }

    &:focus,
    &:hover {
      background-color: $gray-400;
      outline: none;
    }
  }
}
