@import 'shared/styles/bootstrap/_variables.scss';

.layout {
  .footer-push {
    min-height: calc(100vh - 24px);
    padding-bottom: 32px;
  }

  .layout__left-column .jumbotron,
  .layout__left-column {
    background-color: $secondary;
    border-radius: 0;
    height: 100%;
    overflow-x: hidden;

    @include media-breakpoint-up(lg) {
      min-height: 100vh;
    }

    h3 {
      color: $white;
      font-weight: bold;
      text-transform: uppercase;
    }

    hr {
      border-color: $white;
    }
  }
}
